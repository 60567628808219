@tailwind base;
@tailwind components;
@tailwind utilities;

// font Inter
@font-face {
  font-family: Inter Black;
  font-weight: 900;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-Black.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter ExtraBold;
  font-weight: 800;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-ExtraBold.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter Bold;
  font-weight: 700;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-Bold.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter SemiBold;
  font-weight: 600;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-SemiBold.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter Medium;
  font-weight: 500;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-Medium.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter Regular;
  font-weight: 400;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-Regular.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter Light;
  font-weight: 300;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-Light.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter ExtraLight;
  font-weight: 200;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-ExtraLight.otf");
  font-display: swap;
}
@font-face {
  font-family: Inter Thin;
  font-weight: 100;
  src: url("https://storage.googleapis.com/mfc-public/fonts/Inter-Thin.otf");
  font-display: swap;
}

body {
  margin: 0;
  font-family: Inter Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
}
